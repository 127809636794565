// This function is required and used by the background-image mixin.
@function radial-gradient($G1,        $G2,
                       $G3: false, $G4: false,
                       $G5: false, $G6: false,
                       $G7: false, $G8: false,
                       $G9: false, $G10: false,
                       $pos: null,
                       $shape-size: null) {

  $data: _radial-arg-parser($G1, $G2, $pos, $shape-size);
  $G1:  nth($data, 1);
  $G2:  nth($data, 2);
  $pos: nth($data, 3);
  $shape-size: nth($data, 4);

  $type: radial;
  $gradient: compact($G1, $G2, $G3, $G4, $G5, $G6, $G7, $G8, $G9, $G10);

  $type-gradient: $type, $shape-size $pos, $gradient;
  @return $type-gradient;
}


