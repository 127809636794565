@import "neat/neat-helpers";

$visual-grid: false;

$grid-columns: 16;
$max-width: em(768);

// breakpoints
$tablet: new-breakpoint(min-width 480px 16);
$full: new-breakpoint(min-width 600px 16);
