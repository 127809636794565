// Remove last element gutter
@mixin omega($query: block, $direction: default) {
  $table: if(belongs-to(table, $query), true, false);
  $auto: if(belongs-to(auto, $query), true, false);

  @if $direction != default {
    @warn "The omega mixin will no longer take a $direction argument. To change the layout direction, use row($direction) or set $default-layout-direction instead."
  } @else {
    $direction: get-direction($layout-direction, $default-layout-direction);
  }

  @if length($query) == 1 {
    @if $auto {
      &:last-child {
        margin-#{$direction}: 0;
      }
    }

    @else if contains-display-value($query) {
      @if $table {
        padding-#{$direction}: 0;
      }

      @else {
        margin-#{$direction}: 0;
      }
    }

    @else {
      @include nth-child($query, $direction);
    }
  }

  @else if length($query) == 2 {
    @if $table {
      @if $auto {
        &:last-child {
          padding-#{$direction}: 0;
        }
      }

      @else {
        &:nth-child(#{nth($query, 1)}) {
          padding-#{$direction}: 0;
        }
      }
    }

    @else {
      @if $auto {
        &:last-child {
          margin-#{$direction}: 0;
        }
      }

      @else {
        @include nth-child(nth($query, 1), $direction);
      }
    }
  }

  @else {
    @warn "Too many arguments passed to the omega() mixin."
  }
}

@mixin nth-child($query, $direction) {
  $opposite-direction: get-opposite-direction($direction);

  &:nth-child(#{$query}) {
    margin-#{$direction}: 0;
  }

  @if type-of($query) == number {
    &:nth-child(#{$query}+1) {
      clear: $opposite-direction;
    }
  }
}
