// Requires Sass 3.1+
@mixin radial-gradient($G1,        $G2,
                       $G3: false, $G4: false,
                       $G5: false, $G6: false,
                       $G7: false, $G8: false,
                       $G9: false, $G10: false,
                       $pos: null,
                       $shape-size: null,
                       $deprecated-pos1: center center,
                       $deprecated-pos2: center center,
                       $deprecated-radius1: 0,
                       $deprecated-radius2: 460,
                       $fallback: false) {

  $data: _radial-arg-parser($G1, $G2, $pos, $shape-size);
  $G1:  nth($data, 1);
  $G2:  nth($data, 2);
  $pos: nth($data, 3);
  $shape-size: nth($data, 4);

  $full: compact($G1, $G2, $G3, $G4, $G5, $G6, $G7, $G8, $G9, $G10);

  // Strip deprecated cover/contain for spec
  $shape-size-spec: _shape-size-stripper($shape-size);

  // Set $G1 as the default fallback color
  $first-color: nth($full, 1);
  $fallback-color: nth($first-color, 1);

  @if (type-of($fallback) == color) or ($fallback == "transparent") {
    $fallback-color: $fallback;
  }

  // Add Commas and spaces
  $shape-size: if($shape-size, '#{$shape-size}, ', null);
  $pos:        if($pos, '#{$pos}, ', null);
  $pos-spec:   if($pos, 'at #{$pos}', null);
  $shape-size-spec: if(($shape-size-spec != ' ') and ($pos == null), '#{$shape-size-spec}, ', '#{$shape-size-spec} ');

  background-color:  $fallback-color;
  background-image: _deprecated-webkit-gradient(radial, $deprecated-pos1, $deprecated-pos2, $full, $deprecated-radius1, $deprecated-radius2); // Safari <= 5.0 && IOS 4
  background-image: -webkit-radial-gradient(unquote(#{$pos}#{$shape-size}#{$full}));
  background-image: unquote("radial-gradient(#{$shape-size-spec}#{$pos-spec}#{$full})");
}
