// http://colorschemedesigner.com/#
// Hue: 225, R: 4%, B: 64%, G: 38%, RGB: 0B61A4

$main-color: #0B61A4;
$light-color: #3F92D2;
$lighter-color: #66A3D2;
$alt-color: #25567B;
$dark-color: #033E6B;

// Alternative scheme: http://www.colourlovers.com/home/trends/interior-looks/7840/Smith_Brennan_Residence


// basic scheme
$text-color: #1c1c1c;
$link-color: $main-color;
$background-color: #fff;
$alt-background-color: #eee;
$green-background-color: #E6EFC2;
$header-font-family: 'Merriweather', serif;
$large-body-font-size: 1.5rem;
$base-font-family: 'Raleway', sans-serif;
$base-font-size: 1.2rem;
$small-font-size: 1rem;
$base-font-weight: 300;
$tablet-font-size: 1.3rem;