@charset "utf-8";
@import "bourbon/bourbon";
@import "grid-settings";
@import "neat/neat";
@import "variables";
@import "fonts";

body {
	background: $background-color;
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	color: $text-color;
}

fieldset {
	@include media($tablet) {
		flex-direction: row;

		input {
			margin-right: 16px;
		}
	}

	border: none;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;

	input {
		margin-bottom: 16px;
	}
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	font-size: $base-font-size;
	font-style: normal;
}

a {
	color: $link-color;

	&:hover {
		text-decoration: none;
	}
}

h1 {
	@include media($full) {
		font-size: 3.2rem;
	}
	font-family: $header-font-family;
	font-size: 2.4rem;
	margin: 1.8rem 0 1.2rem;
}

h2 {
	@include media($full) {
		font-size: 2.4rem;
	}
	font-family: $header-font-family;
	font-size: 1.8rem;
}

h3 {
	@include media($full) {
		font-size: $large-body-font-size;
	}

	font-size: $tablet-font-size;
	font-weight: 300;
	line-height: 1.49em;
}

iframe {
	float: left;
	margin-right: 2rem;
}

p {
	@include media($tablet) {
		font-size: $tablet-font-size;
	}

	&.meta {
		font-size: $base-font-size;
	}

	&.navigation {
		font-size: $small-font-size;
	}

	font-size: $base-font-size;
	line-height: 1.49em;
}

ul {
	&.freestyle {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		@include media($tablet) {
			font-size: $tablet-font-size;
		}
		font-size: $base-font-size;
		line-height: 135%;
		margin: 0;
		padding: 0.2rem 0;
	}
}

blockquote {
	@include media($tablet) {
		margin-left: 2rem;
		margin-right: 2rem;
	}
	color: #666666;
	font-style: italic;
	margin-left: 1rem;
	margin-right: 0;
}

.block {
	@include row();
}

.container {
	@include outer-container();
	@include media($tablet) {
		max-width: em(600);
	}
	@include media($full) {
		max-width: em(768);
	}
	max-width: em(480);

}

.contents {
	@include span-columns(14);
	@include shift(1);
}

.footer {
	@include media($full) {
		margin-top: 5rem;
	}
	background: $alt-background-color;
	margin-top: 3rem;
	padding: 0 0 2rem;

	a {
		@include media($tablet) {
			font-size: 1.2rem;
		}
		font-size: $small-font-size;
		text-decoration: none;
	}

	p {
		font-size: $base-font-size;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;

		li {
			font-size: $base-font-size;
			line-height: normal;
		}

		.fa {
			width: em(30);
		}
	}

	.contact {
		@include outer-container();
	}
}

.intro {
	display: flex;
	flex-direction: row;
	margin-top: 2em;
	@include media($full) {
		margin-bottom: 2em;
	}

	h1 {
		flex: auto;
		margin-bottom: 0;
		@include media($full) {

		}
	}

	img {
		border-radius: 50%;
		flex: none;
		height: 8em;
		margin-right: 1em;

		@include media($full) {
			height: 12em;
		}
	}
}

.green {
	background-color: $green-background-color;
	display: block;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}
